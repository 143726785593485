import './Home.css'
import { useState, useEffect} from 'react';
import DOMPurify from 'dompurify'

function Home() {
    const [userInput, setUserInput] = useState("");
    //const [serverReply, setServerReply] = useState(null);
     const [username, setUsername] = useState(null);
     const [chat, setChat] = useState([]);

    // const [username, setUsername] = useState("Cid");
    // const [chat, setChat] = useState([
    //     {"type": "assistant", "msg":"What is your name?"},
    //     {"type": "user", "msg":"Cid"},
    //     {"type": "assistant", "msg": "You are transported to the world of Fantasia. You arrive in a green meadow, where a small river is wandering through the fields towards a small village. On the other side, the field is bordered by a lush forest, teeming with life and possibility. Now you, " + username + ", have to decide on the next action to take."},
//         {"type": "user", "msg":"Let's go into the forest"},
//         {"type": "assistant", "msg": "As we begin our journey, we enter a dense forest, the trees towering above us, their branches sweeping across the sky. The sunlight filters through the leaves, casting dappled shadows on the ground below. The air is thick with the scent of pine and damp earth, and the sound of birds singing and leaves rustling fills the air.\
// We walk along a narrow path, occasionally encountering deer or small foxes darting through the underbrush. The deeper we go, the thicker the foliage becomes, making it difficult to see what lies ahead. However, we press on, driven by our curiosity and sense of adventure.\
// Suddenly, the path opens up into a clearing, and we find ourselves face-to-face with a majestic, unicorn-like creature. Its mane shimmers with iridescent colors, and its horn glows with an otherworldly light. It bows its head in greeting, and we approach cautiously, marveling at its beauty.\
// As we stroke its soft, silky muzzle, we hear a faint melody in the distance. We follow the sound, and soon find ourselves at the edge of a crystal-clear pond, surrounded by lush vegetation. In the middle of the pond, a group of fairies are dancing, their wings fluttering gracefully in the wind.\
// We watch in awe, forgetting all about the unicorn and the forest that brought us here. The fairies' laughter is contagious, and we can't help but smile along with them. We feel a sense of joy and wonder that only a magical world like Fantasia could inspire.\
// As we take our leave of the fairies, the unicorn beckons, inviting us to climb onto its back. We hesitantly accept, and it takes off at a gentle pace, soaring above the treetops. From this vantage point, we can see the vast, rolling hills of Fantasia, dotted with villages and castles.\
// The sun begins to set, casting warm, golden light across the sky. We glide back to the edge of the forest, where we bid farewell to the unicorn and the magical creatures we've encountered on our journey. As we make our way back to Cid's side, we feel a sense of fulfillment and satisfaction, knowing that we've experienced something truly extraordinary."},
//    ]);

    useEffect(() => {
        if ( chat.length === 0)
        {
            addChat("assistant", "What is your name?");
        }
    })

    function addChat(type, msg) {
        let id = chat.length;
        let newChat = chat.concat({"id": id, "type": type, "msg":msg});
        setChat(newChat);
    }

    function add2Chat(msg1, msg2) {
        let id = chat.length+1;
        var newChat = chat.concat({"id": id-1, "type": "user", "msg":msg1});
        newChat = newChat.concat({"id": id, "type": "assistant", "msg":msg2});
        setChat(newChat);
    }

    function handleInput() {
        // console.log("User:" + userInput)
        // console.log(username)
        var input = userInput;
        if(input.endsWith('\n'))
        {
            input = input.slice(0, -1); 
        }
        if(!username)
        {
            setUsername(input);
            // addChat("user", input);
            // addChat("assistant", "Welcome " + input + ", what do you want to do in this world?");
            add2Chat(input, "Welcome " + input + ", what do you want to do in this world?");
            //setServerReply("Welcome " + input + ", what do you want to do in this world?")
            setUserInput("");
            return;
        }

        {
            var shortChat = chat.filter(entry => entry.id >= 2);
            var jsonData = {
                "name": username,
                "query": userInput,
                "history": shortChat,
            }
            const uri = 'https://fantasia-world.online/game/rest/game';
            //const uri = 'http://localhost:8080/AdHostBackend/rest/game';
            fetch(uri, {  // Enter your IP address here
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors', 
                body: JSON.stringify(jsonData)
                })
                .then((response) => {
                    if(response.status === 200)
                    {
                        return response.json();
                    }
                    else
                    {
                        return {"response": "HTTP " + response.status}
                    }
                })
                .then(data => {
                    handleServerResponse(userInput, data.response);
                    //console.log("Server: " + data.response);
                });
        }
    }

    function handleServerResponse(userInput, response) {
        add2Chat(userInput, response);
        setUserInput("");
    }

    function addNewlines(text) {
        const text2 = text.replace("\n", "<br>");
        const clean = DOMPurify.sanitize(text2, {USE_PROFILES: {html: true}});
        return clean;
    }

    return (
        <div class=''>
        <div class="header row">
            <h1>Fantasia World</h1>
        </div> 
        <div class="body row scroll-y bg">
            <div class='window'>
                <h2>Welcome!</h2>
                <p class="spaced">Welcome to Fantasia world, where you can live any adventure you want.</p>
            </div>

            <div class='window maintext'>
                { 
                chat.map((c) => {
                    return (
                        <p class={"text-" + c.type} dangerouslySetInnerHTML={{__html: addNewlines(c.msg)}}></p>
                    )
                })
                }
            </div>

            <div class='text-input-window'>
                <form onSubmit={(e) => {
                        e.preventDefault();
                        handleInput();
                        }}>  
                    <textarea autoFocus class="text-input"
                        value={userInput}
                        onKeyUp={(e) => {
                            //console.log("Key pressed:" + e.keyCode)
                            if(e.key === 'Enter') {
                                if(e.shiftKey)
                                {
                                    //nop
                                }
                                else
                                {
                                    handleInput();
                                }
                            }
                        }}
                        onChange={(e) => {
                            setUserInput(e.target.value);
                            }}> 
                        </textarea>
                    <input class="submit-button" type="submit" value="Send"/>
                </form>
            </div>
        </div> 

        </div>

    );

    /**
        <div class="footer row">
            My footer
        </div>
     */

    // return (
    //     <div class="bg">
    //         <div class="container ">
    //             <h1>Fantasia World</h1>
    //             <p class="spaced">Welcome to Fantasia world, where you can live any adventure you want.</p>

    //         </div>
    //         <div class="sidebar">
    //             Hello
    //         </div>
    //         <div class="box">Hello</div>
    //     </div>
    // );
}

export default Home;